<template>
  <section id="year">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 section-head d-flex align-items-center">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0"> 
          {{ title_component.subtitle }}
        </h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="indicadores_list" 
          v-model="indicadores_selected"
          :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-lg-6 offset-lg-3 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
              Filtrar por año:
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <v-select 
              class="selvue-custom"
              :options="year_list" 
              v-model="year_selected"
              :clearable="false">
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">   
      <div class="row">
        <div class="col-12">
          <h5 class="font-title-bold color-gray">Reconocimientos totales</h5>
          <div class="card card-shadow border-round-10 border-0 mb-3" :key="chart_one">
            <div class="card-body">
              <apexchart 
                height="100%" 
                type="line" 
                :options="chartOptions" 
                :series="series"/>
            </div>
          </div>
          <h5 class="font-title-bold color-gray">Total colaboradores activos</h5>
          <div class="card card-shadow border-round-10 border-0" :key="chart_tw">
            <div class="card-body">
              <apexchart 
                height="100%" 
                type="line" 
                :options="chartOptions" 
                :series="series_colab"/>
            </div>
          </div>
        </div>
      </div>
    </template>

  </section>
</template>

<script>
import SectionTabs from "../Section/SectionTabs.vue";
import { mapActions } from "vuex";

  //FF
  import Loading from "@/components/Loading.vue";

export default {
  components: {
    SectionTabs,
    Loading
  },
  data() {
    return {
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-year",
        },
      ],
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle: "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      indicadores_list: [
        { label: "Año", route: "recognize-year" },
        { label: "Gerencia", route: "recognize-management" },
        { label: "Planta", route: "recognize-age" },
        { label: "Top 20", route: "recognize-ranking" },
        { label: "Consultas por colaborador", route: "recognize-contributor" },
        { label: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Año",
      year_list: ["2022","2023", "2024", "2025"],
      year_selected: `${new Date().getFullYear()}`,
      chartOptions: {
        colors: [ "#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6"],
        chart: {
          id: "vuechart",
          type: "area",
          toolbar: {
          show: false,
          },   
        },
        xaxis: {
          type: "category",
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
          tooltip: {
            enabled: false,
          },
          labels: {
            style: {
              fontSize: "16px",
              fontFamily: "Product Sans Regular, Arial, sans-serif",
              fontWeight: "bold",
              colors: [
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
                "#999BA6",
              ],
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -5,
          background: {
            enabled: true,
            borderRadius: "50%",
            borderWidth: 1,
            opacity: 0,
            foreColor: "#757679",
          },
          style: {
            fontSize: "16px",
            fontFamily: "Product Sans Regular, Arial, sans-serif",
            fontWeight: "bold",
            colors: [
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
              "#999BA6",
            ],
          },
        },
        stroke: {
          curve: "straight",
          color: "#39DAD6",
          width: 3,
        },
        yaxis: {
          show: false,
        },
        markers: {
          size: 5,
          colors: [ "#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6","#39DAD6"],
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
      },
      series: [
        {
          name: "years",
          data: [],
        },
      ],
      series_colab: [
        {
          name: "years",
          data: [],
        },
      ],
      chart_one: 0,
      chart_tw: 5,

      //FF
      loading: true,
    };
  },
  mounted() {
    this.getReconocimientos();
    this.getReconocedores();
  },
  watch: {
    year_selected() {
      this.getReconocimientos();
      this.getReconocedores();
    },

    indicadores_selected(value) {
      this.$router.push({ name: value.route });
    }
  },

  methods: {
    ...mapActions("recognizeModule", ["getCantidadReconocimientosAnualAction", "getCantidadReconocedoresAnualAction"]),

    // Los reconocimientos por mes del año seleccionado
    async getReconocimientos() {
      this.loading = true;
      const payload = {
        id_empresa: this.id_empresa,
        year: this.year_selected
      };

      try {        
        const res = await this.getCantidadReconocimientosAnualAction(payload);
        this.loadReconocimientos(res);
      } catch (error) {
        this.toastGenericError();
        console.error(error);
      }
      this.loading = false;
    },

    // Funcion que ordena los reconocimientos para enviarlos al grafico
    loadReconocimientos(r) {
      var arr = [];
      for (var i = 0; i < r.length; i++) {
        var num = Number(r[i].cantidad);
        if (isNaN(num)) {
          arr.push(0);
        } else {
          arr.push(num);
        }
      }
      this.series[0].data = arr;
      this.chart_one = 1 + this.chart_one;
    },

    // Los recedores por mes del año seleccionado
    async getReconocedores() {
      const payload = {
        id_empresa: this.id_empresa,
        year: this.year_selected
      };
      try {        
        const res = await this.getCantidadReconocedoresAnualAction(payload);
        this.loadRecedores(res);
      } catch (error) {
        this.toastGenericError();
        console.error(error);
      }
    },

    // Funcion que ordena los recedores para enviarlos al grafico
    loadRecedores(r) {
      var arr = [];
      for (var i = 0; i < r.length; i++) {
        var num = Number(r[i].cantidad);
        if (isNaN(num)) {
          arr.push(0);
        } else {
          arr.push(num);
        }
      }
      this.series_colab[0].data = arr;
      this.chart_tw = 1 + this.chart_tw;
    }

  },
};
</script>
